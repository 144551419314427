



























import ImageContainer from '@/components/ImageContainer.vue';
import ImageProperty from '@/components/ImageProperty.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AlbumModel from '@/models/Album';
import moment from 'moment';

@Component({
  name: 'Album',
  components: {
    ImageContainer,
    ImageProperty,
    Album: Album
  }
})
export default class Album extends Vue {
  @Prop() private album!: AlbumModel
  private isOpen: boolean = false;
  private thumbnailQuery: string = '';

  private hasChildren() {
    return this.album.children && this.album.children.length > 0;
  }

  private toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  private refreshThumbnail() {
    this.thumbnailQuery = 'refresh=1&_='+(new Date()).getTime();
  }

  private dateRange(): string {
    const firstShot = moment(this.album.firstShot);
    const lastShot = moment(this.album.lastShot);

    if (this.album.firstShot === this.album.lastShot) {
      return firstShot.format('D. MMMM YYYY');
    } else if (firstShot.year() != lastShot.year()) {
      return firstShot.format('DD.MM.YYYY')+' - '+lastShot.format('DD.MM.YYYY');
    } else if (firstShot.month() != lastShot.month()) {
      return firstShot.format('DD.MM.')+' - '+lastShot.format('DD.MM.YYYY');
    }

    return firstShot.format('DD.')+' - '+lastShot.format('DD. MMMM YYYY');
  }
}
