








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Loader from '@/components/Loader.vue';

@Component({
  components: {Loader},
})
export default class ImageContainer extends Vue {
  @Prop() private source!: string;
  private src: string =  '';
  private isLoading: boolean = false;

  $refs!: {
    image: HTMLImageElement,
  }

  mounted() {
    this.loadImage(this.source);
  }

  @Watch('source')
  onSourceChange(newValue: string) {
    this.loadImage(newValue);
  }

  public loadImage(source: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.src = source;
      this.isLoading = true;

      const img = this.$refs.image;
      const image = this;

      function loadEventListener() {
        img.removeEventListener('load', loadEventListener);
        img.removeEventListener('error', errorEventListener);
        image.isLoading = false;
        resolve();
      }

      function errorEventListener() {
        img.removeEventListener('load', loadEventListener);
        img.removeEventListener('error', errorEventListener);
        image.isLoading = false;
        reject('Image could not be loaded');
      }

      if (img) {
        img.addEventListener('load', loadEventListener);
        img.addEventListener('error', errorEventListener);
      }

      this.$forceUpdate();
    }).catch(console.error);
  }
}
