
































import Album from '@/components/Album.vue'
import Loader from '@/components/Loader.vue';
import NavBar from '@/components/NavBar.vue';
import {CookieManager} from '@/tools/CookieManager';
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import AlbumModel from '@/models/Album';

@Component({
  components: {
    Loader,
    Album,
    NavBar,
  }
})
export default class Home extends Vue {
  albums: AlbumModel[] = [];

  protected isLoading: boolean = false;
  protected loader: Promise<void>|null = null;
  protected shareForm = {code: ''};

  mounted() {
    this.refreshAlbums();
    this.$store.commit('setSubPageTitle', {subPageTitle: ''});
  }

  protected refreshAlbums() {
    if (!this.loader) {
      this.isLoading = true;
      this.loader = axios.get('/api/albums').then(response => {
        this.loader = null;
        this.albums = response.data;
        this.isLoading = false;
      }).catch(reason => {
        this.loader = null;
        console.log(reason);
        this.isLoading = false;
      });
    }
  }

  public addShareCode(code: string) {
    this.shareForm.code = '';
    if (CookieManager.addShareCode(code)) {
      this.loader = null;
      this.refreshAlbums();
    }
  }
}
