








import SvgIcon from '@/components/SvgIcon.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {SvgIcon}
})
export default class ImageProperty extends Vue {
  @Prop() private label!: string;
  @Prop() private icon!: string;
  @Prop() private value!: string;
  @Prop({type: Boolean}) private noText!: boolean;
}
