































































































import ImageContainer from '@/components/ImageContainer.vue';
import ImageDetails from '@/components/ImageDetails.vue';
import ImageProperty from '@/components/ImageProperty.vue';
import Loader from '@/components/Loader.vue';
import NavBar from '@/components/NavBar.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import Image from '@/models/Image';
import {BSidebar} from 'bootstrap-vue';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import AlbumModel from '@/models/Album';
import { Route } from 'vue-router';
import {mapState} from 'vuex';

// eslint-disable-next-line
export enum SORT_DIR {ASC = 'asc', DESC = 'desc'}

@Component({
  components: {
    Loader,
    ImageContainer,
    ImageProperty,
    SvgIcon,
    NavBar,
    ImageDetails
  },
  computed: mapState(['slideshowSpeed']),
})
export default class AlbumGallery extends Vue {
  $refs!: {
    imageDetail: ImageDetails,
    sidebar: BSidebar,
  }
  album: AlbumModel|null = null;
  displayedImage: Image|null = null;
  sorting: {col: string, dir: SORT_DIR}[] = [{col: 'shot', dir: SORT_DIR.ASC}];

  protected isLoading: boolean = false;
  protected loader: Promise<void>|null = null;
  protected routes: string[] = [];
  protected readonly slideshowSpeed!: number;
  protected nextSlideshowSpeed: number = this.$store.state.slideshowSpeed / 1000;

  mounted() {
    this.routes.push(this.$route.fullPath);
    this.refreshAlbum();
  }

  beforeRouteUpdate(to: Route, from: Route, next: any) {
    this.routes.push(to.fullPath);
    this.displayedImage = to.query.image &&
        this.album?.images.filter(image => image.id === to.query.image)[0] ||
        null;
    next();
  }

  sortingDir() {
    return this.sorting[0]?.dir;
  }

  protected refreshAlbum() {
    if (!this.loader) {
      this.isLoading = true;
      this.loader = axios.get('/api/albums/'+this.$route.params.albumId+'/images').then(response => {
        this.loader = null;
        this.album = response.data;
        this.$store.commit('setSubPageTitle', {subPageTitle: this.album?.name});
        this.album?.images.sort((a, b) => {
          return moment(a.shot).diff(b.shot);
        });
        if (this.$route.query.image) {
          this.displayedImage = this.album?.images.filter(image => image.id === this.$route.query.image)[0] || null;
        }
        this.isLoading = false;
      }).catch(reason => {
        this.loader = null;
        console.log(reason);
        this.isLoading = false;
      });
    }
  }

  protected setSlideshowSpeed(value: string) {
    this.$store.commit('setSlideshowSpeed', {slideshowSpeed: parseInt(value, 10)*1000})
  }

  protected imageSource(imageId: string) {
    return this.$store.state.windowWidth > 576 ?
        '/api/images/'+imageId+'/thumbnail.jpg?height=300' :
        '/api/images/'+imageId+'/thumbnail.jpg?width=576'
  }

  switchedImage({image}: {image: Image}) {
    this.$router[this.displayedImage ? 'replace' : 'push']('/album/'+this.album?.id+'?image='+image.id);
  }

  showImage(imageId: string) {
    this.$router[this.displayedImage ? 'replace' : 'push']('/album/'+this.album?.id+'?image='+imageId);
  }

  exitFullscreen() {
    if (this.$refs.imageDetail) {
      this.$refs.imageDetail.exitFullscreen();
    }
  }

  closeSidebar() {
    if (this.$refs.sidebar) {
      this.$refs.sidebar.hide();
    }
  }

  protected startSlideshow(image?: Image, random: boolean = false) {
    if (!this.album) {
      return;
    }

    image = image || this.album.images[0];
    if (!image) {
      return;
    }

    if (!this.$refs.imageDetail || this.displayedImage != image) {
      this.$router.push('/album/'+this.album.id+'?image='+image.id+'').then(() => {
        this.$refs.imageDetail.startSlideshow(random);
      });
    } else {
      this.$refs.imageDetail.startSlideshow(random);
    }

  }

  get imageIds(): string[] {
    return this.album?.images.map(image => image.id) || [];
  }

  protected closeImage() {
    let p: number = this.routes.indexOf('/album/'+this.album?.id);
    if (p > -1) {
      this.$router.go(-1);
    } else {
      this.$router.push('/album/'+this.album?.id);
    }
  }

  protected formatDate(image: Image): string {
    return moment(image.shot).format('lll');
  }
}
