<template>
  <div class="svg-icon" v-html="svg"></div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['icon'],
  data() {
    return {
      svg: null
    };
  },
  mounted() {
    axios.get(require(`@/assets/icons/${this.icon}.svg`)).then((response) => this.svg = response.data);
  }
}
</script>
<style lang="scss">
.svg-icon {
  display: inline;

  > svg {
    fill: currentColor;
    height: 1em;
    width: 1em;
    vertical-align: -0.15em;
  }
}
</style>
