import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

interface RootState {
  windowWidth: number,
  slideshowSpeed: number,
  subPageTitle: string,
  popperOptions: any,
}

const persist = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: ({slideshowSpeed}) => ({slideshowSpeed}),
});

export default new Vuex.Store<RootState>({
  state: {
    windowWidth: window.innerWidth,
    slideshowSpeed: 8000,
    subPageTitle: '',
    popperOptions: {eventsEnabled: false, modifiers: {applyStyle: {enabled: false}}},
  },
  mutations: {
    updateWindowWidth (state, {windowWidth}: {windowWidth: number}) {
      state.windowWidth = windowWidth;
    },
    setSlideshowSpeed (state, {slideshowSpeed}: {slideshowSpeed: number}) {
      state.slideshowSpeed = slideshowSpeed;
    },
    setSubPageTitle (state, {subPageTitle}: {subPageTitle: string}) {
      state.subPageTitle = subPageTitle;
      document.title = (subPageTitle ? subPageTitle + ' - ' : '') + 'Network Gallery';
    },
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger(), persist.plugin] : [persist.plugin],
});
