<template>
  <div class="svg-layer" :style="{display: isLoading ? 'block' : 'none'}">
    <div class="svg-loader" :style="{justifyContent: alignment}">
      <svg class="svg-container" :height="width" :width="height" viewBox="0 0 100 100">
        <circle class="loader-svg bg" cx="50" cy="50" r="40"></circle>
        <circle class="loader-svg animate" cx="50" cy="50" r="40"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    width: {type: Number, default: 50},
    height: {type: Number, default: 50},
    alignment: {type: String, default: 'center'},
    isLoading: {type: Boolean, default: false}
  }
}
</script>

<style lang="scss">
.svg-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background-color: rgba(0,0,0,0.7);*/
  z-index: 50;

  .svg-loader {
    display: flex;
    position: absolute;
    align-content: space-around;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader-svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: square;
    stroke: rgba(255,255,255,0.5);

    &.bg {
      stroke-width: 5px;
      stroke: rgba(255,255,255,0.1);
      fill: none
    }

    &.animate {
      stroke-dasharray: 242.6;
      animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite
    }
  }
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0
  }
}
</style>
