export class CookieManager {
    public static addShareCode(code: string) {
        const cookie = document.cookie.split(';').filter(cookie => {
            return cookie.startsWith('shares=');
        })[0]?.substr(7) || null;
        const codes = cookie?.split(',') || [];

        if (!codes.includes(code)) {
            codes.push(code);
            const expires = new Date();
            expires.setTime(expires.getTime()+10*365*24*60*60*1000); // 10 years
            document.cookie = 'shares='+codes.join(',')+'; expires='+expires.toUTCString()+'; path=/';
            return true;
        }

        return false;
    }
}
