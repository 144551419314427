


























































































import ImageContainer from '@/components/ImageContainer.vue';
import ImageProperty from '@/components/ImageProperty.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import Image from '@/models/Image';
import moment from 'moment';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';

@Component({
  components: {ImageContainer, ImageProperty, SvgIcon},
  computed: mapState(['slideshowSpeed']),
})
export default class ImageDetails extends Vue {
  $refs!: {
    image: ImageContainer,
  }

  // properties of the html element
  @Prop() private images!: Image[];
  @Prop() private startImage!: Image;

  // properties from state
  private slideshowSpeed!: number;

  // instance properties
  private image: Image|undefined;
  private fullscreen: boolean = false;
  private pos: number = 0;
  private history: Image[] = [];
  private queue: Image[] = [];

  private showNavbar: boolean = true;
  private navBarTimer: number|null = null;

  private hideTitle: boolean = true;
  private titleTimer: number|null = null;

  public slideshowActive: boolean = false;
  private slideshowRunning: boolean = false;
  private slideshowTimer: number|null = null;

  get next() {
    return this.queue.length > 0;
  }

  get previous() {
    return this.history.length > 0;
  }

  private setImage(image: Image) {
    this.image = image;
    this.image.versions?.sort((a, b) => {
      return moment(a.mtime).diff(b.mtime);
    });
  }

  created() {
    if (!this.image) {
      if (this.startImage) {
        this.setImage(this.startImage);
        const pos = this.images.indexOf(this.startImage);
        this.queue = this.images.slice(pos+1)
        this.history = this.images.slice(0, pos);
      } else {
        this.pos = 0;
        this.setImage(this.images[this.pos]);
        this.queue = this.images.slice(1)
      }
    }
  }

  mounted() {
    document.body.className += ' modal-open';
    document.body.addEventListener('mousemove', this.mouseMoved);
    document.body.addEventListener('touchmove', this.touchMoved);
    document.body.addEventListener('keyup', this.keyPressed);
    this.displayNavBar(3000);
    this.displayTitle(3000);
  }

  destroyed() {
    document.body.className = document.body.className.replace(/ ?modal-open/, '');
    document.body.removeEventListener('mousemove', this.mouseMoved)
    document.body.removeEventListener('touchmove', this.touchMoved)
    document.body.removeEventListener('keyup', this.keyPressed);
  }

  mouseMoved(ev: MouseEvent) {
    if (ev.y < window.innerHeight/3) {
      this.displayNavBar(2000);
    }
  }

  touchMoved() {
    this.displayNavBar(5000);
  }

  keyPressed(event: KeyboardEvent) {
    // remember to prevent default and exit the handler
    switch (event.key) {
      case 'ArrowRight':
        this.next && this.nextImage();
        break;

      case 'ArrowLeft':
        this.previous && this.previousImage();
        break;

      case ' ':
        if (this.slideshowActive) {
          this.slideshowRunning ? this.pauseSlideshow() : this.continueSlideshow();
        }
        break;

      case 'f':
      case 'F':
        this.fullscreen ? this.exitFullscreen() : this.startFullscreen();
        break;

      case 'Escape':
        this.$emit('close-image');
        break;

      default:
        return;
    }

    event.preventDefault();
  }

  displayNavBar(timeout: number = 0) {
    this.showNavbar = true;
    this.navBarTimer && clearTimeout(this.navBarTimer);
    if (timeout > 0)  {
      this.navBarTimer = setTimeout(() => this.showNavbar = false, timeout);
    }
  }

  displayTitle(timeout: number = 0) {
    this.hideTitle = false;
    this.titleTimer && clearTimeout(this.titleTimer);
    if (timeout > 0)  {
      this.titleTimer = setTimeout(() => this.hideTitle = true, timeout);
    }
  }

  startFullscreen() {
    this.fullscreen = true;
    this.displayTitle(3000);

    // @todo return a Promise indicating if the bigger image got loaded
    this.$fullscreen.toggle(this.$el, {
      callback: isFullscreen => {
        this.fullscreen = isFullscreen;
        if (!isFullscreen) {
          if (this.slideshowActive) {
            this.pauseSlideshow();
          }
        } else {
          this.$emit('entered-fullscreen');
        }
      }
    }).catch(() => {
      this.fullscreen = false;
    });
  }

  exitFullscreen() {
    this.$fullscreen.exit()
  }

  gotoImage(image: Image): Promise<void> {
    this.setImage(image);
    return this.$refs.image.loadImage(this.imageSource(image.id)).then(() => {
      this.displayTitle(4000);
      this.$emit('switched-image', {image: this.image});
    });
  }

  nextImage(): Promise<void> {
    this.pos++;
    const image = this.queue.shift();

    if (!image) {
      // @todo what now? start over? ask the user?
      this.slideshowActive && this.stopSlideshow();
      return Promise.reject('No more images');
    }

    this.image && this.history.push(this.image);
    return this.gotoImage(image);
  }

  previousImage(): Promise<void> {
    this.pos--;
    const image = this.history.pop();

    if (!image) {
      // @todo what now? start over? ask the user?
      return Promise.reject('No more images');
    }

    this.image && this.queue.unshift(this.image);
    if (this.slideshowActive) {
      this.pauseSlideshow();
    }
    return this.gotoImage(image);
  }

  protected imageSource(imageId: string) {
    if (!this.fullscreen) {
      return this.$store.state.windowWidth > 576 ?
          '/api/images/'+imageId+'/thumbnail.jpg?width=1024' :
          '/api/images/'+imageId+'/thumbnail.jpg?width=576'
    }

    const imageResolutions = {
      portrait: [{x: 576, y: 1024}, {x: 1080, y: 1920}, {x: 1440, y: 2560}],
      landscape: [{x: 1366, y: 768}, {x: 1920, y: 1080}, {x: 2560, y: 1440}, {x: 3840, y: 2160}],
    };
    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
    const windowResolution = {
      x: window.innerWidth * window.devicePixelRatio,
      y: window.innerHeight * window.devicePixelRatio,
    }

    let resolution: {x: number, y: number}|null = null;
    for (let availableSize of imageResolutions[orientation]) {
      if (availableSize.x > windowResolution.x && availableSize.y > windowResolution.y) {
        resolution = availableSize;
        break;
      }
    }

    // if nothing fits we use the highest resolution
    if (!resolution) {
      resolution = imageResolutions[orientation][imageResolutions[orientation].length-1];
    }

    return '/api/images/'+imageId+'/thumbnail.jpg?width='+resolution.x+'&height='+resolution.y;
  }

  protected loadFullVersion(versionId?: number, download: boolean = false) {
    versionId = versionId ?? this.image?.activeVersion.id;
    let url = '/api/versions/'+versionId+'.jpg';
    if (download) {
      url += '?download=true';
    }
    window.open(url, 'gallery_version_'+versionId);
  }

  startSlideshow(random: boolean = false) {
    this.slideshowActive = true;
    if (!this.fullscreen) {
      this.startFullscreen();
    }

    if (random) {
      this.history = [];
      this.queue = this.images.slice(0);
      this.queue.sort(() => Math.random() - 0.5)
      const img = this.queue.shift()
      if (img) this.gotoImage(img);
    }

    this.slideshowRunning = true
    this.startTimer();
  }

  stopSlideshow() {
    if (this.fullscreen) {
      this.exitFullscreen();
    }
    this.pauseSlideshow();
    this.slideshowActive = false;

    // @todo keep the random order or reset the history/queue based on the current img?
  }

  protected pauseSlideshow() {
    this.slideshowRunning = false;
    !this.slideshowTimer || window.clearTimeout(this.slideshowTimer);
  }

  protected continueSlideshow() {
    this.slideshowRunning = true;
    this.nextImage().then(() => this.startTimer());
  }

  protected startTimer() {
    !this.slideshowTimer || window.clearTimeout(this.slideshowTimer);
    this.slideshowTimer = window.setTimeout(() => {
      this.nextImage().then(() => {
        if (this.slideshowRunning) {
          this.startTimer();
        }
      });
    }, this.slideshowSpeed);
  }
}
