





















import {CookieManager} from '@/tools/CookieManager';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import moment from 'moment';
import 'moment/locale/de';
import VueFullscreen from 'vue-fullscreen';
import VueMoment from 'vue-moment';
import { Component, Vue } from 'vue-property-decorator';

import Album from '@/components/Album.vue';

moment.locale('de'); // yes at the moment this is hardcoded german

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMoment, { moment });
Vue.use(VueFullscreen);

@Component({
  components: {
    AlbumTree: Album,
  },
})
export default class App extends Vue {
  public version: string = process.env.VERSION;

  public countIncrement(amount: number = 1) {
    this.$store.commit('countIncrement', {amount});
  }

  public mounted() {
    const match = location.hash.match(/code=([a-zA-Z0-9]+)/);
    if (match) {
      CookieManager.addShareCode(match[1])
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    });
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  public onResize() {
    this.$store.commit('updateWindowWidth', {windowWidth: window.innerWidth});
  }
}
